import { ScreenConfig, Title } from '@kisskissbankbank/kitten'
import BackingCard from 'kiss/components/cards/backing-card'
import { useTranslation } from 'kiss/hooks/use-translation'
import KissDonationCard from 'kiss/modules/components/donation-card'

import {
  getAvailableRewards,
  getProjectState,
  getSoldOutRewards,
  isProjectDonationEnabled,
} from 'kiss/modules/project-page/page-state/selectors'
import { isFinished, isNotPublished } from 'kiss/utils/project/state'
import {
  FOUR_IN_XL_CONTAINER,
  THREE_IN_CONTAINER,
} from 'kiss/utils/responsive-image/image-width-definitions'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import DesktopModal from '../../components/reward-modal/desktop'
import ActiveRewardCard from './reward-card'

const imageProps = {
  breakpoints: [ScreenConfig.L.min, ScreenConfig.XL.min],
  imageSizesInViewport: [THREE_IN_CONTAINER, FOUR_IN_XL_CONTAINER],
  responsiveImageSizes: [190, 220, 275, 325, 375, 414],
}

const RewardsBase = () => {
  const [modalReward, setModalReward] = useState(null)

  const t = useTranslation()
  const projectDisabledRewards = useSelector(getSoldOutRewards)
  const projectEnabledRewards = useSelector(getAvailableRewards)
  const projectDonationEnabled = useSelector(isProjectDonationEnabled)
  const projectState = useSelector(getProjectState)

  const isProjectNotPublished = isNotPublished(projectState)
  const isProjectFinished = isFinished(projectState)

  const handleModalClose = () => {
    setModalReward(null)
  }

  const handleModal = (reward) => {
    if (modalReward) return null

    setModalReward(reward)
  }

  return (
    <>
      {projectEnabledRewards.length > 0 && (
        <>
          <Title
            tag="h2"
            modifier="senary"
            className="k-u-margin-bottom-triple"
          >
            {isProjectFinished
              ? t('rewards.finished_title')
              : t('rewards.main_title')}
          </Title>
          {projectEnabledRewards.map((reward, index) => (
            <ActiveRewardCard
              data-test-id={`reward-card-${index}`}
              reward={reward}
              index={index}
              key={reward.uuid}
              handleModal={handleModal}
              imageProps={imageProps}
              isProjectNotPublished={isProjectNotPublished}
              isProjectFinished={isProjectFinished}
            />
          ))}
        </>
      )}

      {projectDonationEnabled && (
        <>
          <Title
            tag="h2"
            modifier="quinary"
            className="k-u-margin-bottom-double"
          >
            {t('rewards.donation_title')}
          </Title>
          <KissDonationCard
            className="k-u-margin-bottom-quadruple"
            isProjectNotPublished={isProjectNotPublished}
            isProjectFinished={isProjectFinished}
          />
        </>
      )}

      {projectDisabledRewards.length > 0 && (
        <>
          <Title
            tag="h2"
            modifier="quinary"
            className="k-u-margin-bottom-double"
          >
            {t('rewards.unavailable_title')}
          </Title>
          {projectDisabledRewards.map((reward, index) => (
            <BackingCard
              data-test-id={`reward-card-${index}`}
              reward={reward}
              key={reward.uuid}
              className="k-u-margin-bottom-quadruple"
              imageProps={imageProps}
              isProjectNotPublished={isProjectNotPublished}
              isProjectFinished={isProjectFinished}
              isUnavailable
            />
          ))}
        </>
      )}

      {!!modalReward && (
        <DesktopModal
          reward={modalReward}
          modalProps={{
            onAfterClose: handleModalClose,
          }}
        />
      )}
    </>
  )
}

export default RewardsBase
