import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import merge from 'lodash/fp/merge'

export const NAME = 'CONTRIBUTE'
export const UPDATE_ADDRESS = `${NAME}/UPDATE_ADDRESS`
export const UPDATE_BILLING_ADDRESS = `${NAME}/UPDATE_BILLING_ADDRESS`
export const UPDATE_DONATION = `${NAME}/UPDATE_DONATION`
export const UPDATE_TIP = `${NAME}/UPDATE_TIP`
export const ADD_VARIATION = `${NAME}/ADD_VARIATION`
export const REMOVE_VARIATION = `${NAME}/REMOVE_VARIATION`
export const LOAD_SIMILAR_PROJECTS_LIST = `${NAME}/LOAD_SIMILAR_PROJECTS_LIST`
export const SELECT_COUNTRY = `${NAME}/SELECTED_COUNTRY`
export const UPDATE_CONTRIBUTION = `${NAME}/UPDATE_CONTRIBUTION`
export const UPDATE_CART = `${NAME}/UPDATE_CART`
const UPDATE_POLLING = `${NAME}/UPDATE_POLLING`

export const SCROLL_SPEED = 0
export const CONTRIBUTE_FOOTER_ID = 'contribute-footer'

const initialState = {
  project: {
    slug: null,
    name: null,
    isPermanent: false,
    organization: {
      name: null,
    },
    owner: {
      username: null,
    },
    pageColors: {
      primary_100: '',
      primary_300: '',
      primary_500: '',
      primary_700: '',
      primary_900: '',
    },
    cart: {
      uuid: null,
      total: null,
      donation: null,
      variations: [],
      shippingAddress: {
        gender: 'mr',
        firstName: '',
        lastName: '',
        companyName: '',
        phone: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        postalCode: '',
        city: '',
        countryCode: '',
      },
      contribution: {
        id: null,
        hideContributor: false,
        hideAmount: false,
      },
    },
  },
  polling: false,
}

const reducer = (state = initialState, action) => {
  if (isEmpty(state)) state = initialState

  switch (action.type) {
    case UPDATE_POLLING:
      return { ...state, polling: action.payload }
    case SELECT_COUNTRY:
      return {
        ...state,
        project: {
          ...state.project,
          cart: {
            ...state.project.cart,
            shippingAddress: {
              ...state.project.cart.shippingAddress,
              ...action.payload.project.cart.shippingAddress,
            },
          },
        },
      }
    case UPDATE_DONATION:
    case ADD_VARIATION:
    case REMOVE_VARIATION:
    case UPDATE_TIP:
    case UPDATE_CONTRIBUTION:
      return {
        ...state,
        project: {
          ...state.project,
          cart: {
            ...state.project.cart,
            ...action.payload,
          },
        },
      }

    case UPDATE_CART:
      return merge(state)({
        project: {
          cart: action.payload,
        },
      })
    case UPDATE_ADDRESS:
      return {
        ...state,
        project: {
          ...state.project,
          cart: {
            ...state.project.cart,
            shippingAddress: action.payload,
          },
        },
      }

    case UPDATE_BILLING_ADDRESS:
      return {
        ...state,
        project: {
          ...state.project,
          cart: {
            ...state.project.cart,
            billingAddress: action.payload,
          },
        },
      }
    case LOAD_SIMILAR_PROJECTS_LIST:
      return { ...state, similarProjets: action.payload }
    default:
      return state
  }
}

export const getProjectSlug = (state) =>
  getOr(null)(`${NAME}.project.slug`)(state)
export const isPermanentProject = (state) =>
  getOr(null)(`${NAME}.project.isPermanent`)(state)
export const getProjectUuid = (state) =>
  getOr(null)(`${NAME}.project.uuid`)(state)
export const getProjectId = (state) => getOr(null)(`${NAME}.project.id`)(state)
export const getProjectName = (state) =>
  getOr(null)(`${NAME}.project.name`)(state)
export const isOwner = (state) =>
  getOr(false)(`${NAME}.project.currentUser.isOwner`)(state)

export const getProjectUrl = (state) =>
  getOr(null)(`${NAME}.project.publicUrl`)(state)
const getProjectOwner = (state) =>
  getOr(null)(`${NAME}.project.owner.username`)(state)
export const getSimilarProjects = (state) =>
  getOr([])(`${NAME}.similarProjets`)(state)

export const getPageColors = (state) => {
  const colors = get(`${NAME}.project.pageColors`)(state)
  return {
    '--color-primary-100': colors.primary_100,
    '--color-primary-300': colors.primary_300,
    '--color-primary-500': colors.primary_500,
    '--color-primary-700': colors.primary_700,
    '--color-primary-900': colors.primary_900,
  }
}
export const getRawPageColors = (state) =>
  get(`${NAME}.project.pageColors`)(state)

//organization
const getOrganizationName = (state) =>
  getOr(null)(`${NAME}.project.organization.name`)(state)

//OrganizationOrOwner
export const getOrganizationOrOwnerName = (state) =>
  getOrganizationName(state) || getProjectOwner(state)

export const updatePolling = (polling) => (dispatch) => {
  return dispatch({ type: UPDATE_POLLING, payload: polling })
}

export const isPolling = (state) => getOr(false)(`${NAME}.polling`)(state)

export const hasRecurringDonation = (state) =>
  getOr(false)(`${NAME}.project.cart.recurringDonation`)(state)

export const hasDonationLine = (state) =>
  getOr(false)(`${NAME}.project.cart.hasDonation`)(state)

export default { [NAME]: reducer }
